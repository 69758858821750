import '@splidejs/splide/dist/css/splide.min.css';
import '../styles/Splide.css'
import '../styles/Splide.responsive.css'
import '../styles/globals.css'
import '../styles/globals.mobile.css'
import '../styles/Header.component.css'
import '../styles/Header.component.mobile.css'
import '../styles/Footer.component.css'
import '../styles/Footer.component.mobile.css'
import '../styles/Social.component.css'
import '../styles/Home.css'
import '../styles/Home.mobile.css'

import {
    AuthenticationContextProvider,
    AxiosInstanceContextProvider,
    ConfiguredQueryClientProvider
} from '@zmvp/app'
import { BaselineProvider, ErrorHandlingProvider } from '@zmvp/material-ui'
import Head from 'next/head'
import React from 'react'

import theme from 'components/theme'

export default function App({ Component, pageProps }) {
    return (
        <BaselineProvider theme={theme}>
            <Head>
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width"
                />
                <title>LovePlates</title>
                <link
                    href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap"
                    rel="stylesheet"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/favicon-32x32.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/favicon-16x16.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/apple-touch-icon.png"
                />
                <link rel="manifest" href="/site.webmanifest" />
            </Head>
            <ErrorHandlingProvider>
                <AxiosInstanceContextProvider
                    baseUrl={process.env.NEXT_PUBLIC_API_URL}
                >
                    <AuthenticationContextProvider>
                        <ConfiguredQueryClientProvider>
                            <Component {...pageProps} />
                        </ConfiguredQueryClientProvider>
                    </AuthenticationContextProvider>
                </AxiosInstanceContextProvider>
            </ErrorHandlingProvider>
        </BaselineProvider>
    )
}
