import { Button, CircularProgress, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative'
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        color: theme.palette.secondary.main
    },
    fullWidth: {
        width: '100%'
    }
}))

export default function ProgressButton({
    type,
    isLoading,
    disabled = false,
    children,
    classes: extraClasses = {},
    className,
    fullWidth,
    onClick,
    color,
    variant,
    size
}) {
    const classes = useStyles()
    return (
        <div
            className={clsx(
                classes.root,
                extraClasses.root,
                fullWidth && classes.fullWidth
            )}
        >
            <Button
                type={type}
                fullWidth={fullWidth}
                onClick={onClick}
                className={clsx(extraClasses.button, className)}
                disabled={isLoading || disabled}
                color={color}
                variant={variant}
                size={size}
            >
                {children}
            </Button>
            {isLoading && (
                <CircularProgress
                    size={24}
                    className={clsx(classes.progress, extraClasses.progress)}
                />
            )}
        </div>
    )
}

ProgressButton.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    disabled: PropTypes.bool
}
