import { Input, InputAdornment } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import { useDebouncedState } from '@zmvp/forms'
import React from 'react'

export default function SearchInput({
    value,
    onChange,
    adornmentPosition = 'start',
    ...props
}) {
    const [query, setQuery] = React.useState(value || '')

    useDebouncedState(query, {
        onUpdate: onChange
    })

    const onChangeHandler = React.useCallback(e => {
        setQuery(e.target.value)
    }, [])

    return (
        <Input
            startAdornment={
                <InputAdornment position={adornmentPosition}>
                    <SearchIcon />
                </InputAdornment>
            }
            value={query}
            onChange={onChangeHandler}
            autoComplete="off"
            {...props}
        />
    )
}
