import * as Sentry from '@sentry/nextjs'
import React from 'react'
import UnexpectedErrorDialog from './UnexpectedErrorDialog'

const ErrorHandlingContext = React.createContext()

export function ErrorHandlingProvider({
    children,
    onNetworkError,
    onError
}) {
    const errorDialog = React.useRef()

    const [resets, setResets] = React.useState(0)

    const handleError = React.useCallback(
        error => {
            if (onError) {
                onError(error)
                return
            }

            const eventId = Sentry.captureException(error)

            errorDialog.current.open({
                type: 'generic',
                eventId,
                error
            })
        },
        [onError]
    )

    const handleNetworkError = React.useCallback(
        error => {
            if (onNetworkError) {
                onNetworkError(error)
                return
            }

            const eventId = Sentry.captureException(error)

            errorDialog.current.open({
                type: !error.response ? 'network' : 'generic',
                eventId,
                error
            })
        },
        [onNetworkError]
    )

    const value = React.useMemo(
        () => ({
            handleError,
            handleNetworkError
        }),
        [handleError, handleNetworkError]
    )

    return (
        <ErrorHandlingContext.Provider value={value}>
            <Sentry.ErrorBoundary
                fallback={function (fallbackProps) {
                    const canReset = resets === 0
                    const resetError = function () {
                        if (!canReset) {
                            return
                        }

                        setResets(resets + 1)
                        fallbackProps.resetError()
                    }
                    const props = {
                        ...fallbackProps,
                        type: 'generic',
                        open: true,
                        isTerminalError: true,
                        canResetError: canReset,
                        resetError
                    }
                    return <UnexpectedErrorDialog {...props} />
                }}
            >
                {children}
            </Sentry.ErrorBoundary>
            <UnexpectedErrorDialog ref={errorDialog} />
        </ErrorHandlingContext.Provider>
    )
}

export function useErrorHandler() {
    return React.useContext(ErrorHandlingContext)
}
