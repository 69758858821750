import jwtDecode from 'jwt-decode'
import React from 'react'

import { useAxiosRequest } from '../axios'

const AuthenticationContext = React.createContext()

function useOnceAxiosRequest(options) {
    const inFlight = React.useRef()
    const request = useAxiosRequest(options)

    return React.useMemo(
        () =>
            async function () {
                if (inFlight.current) {
                    return await inFlight.current
                }

                inFlight.current = request()

                try {
                    return await inFlight.current
                } finally {
                    inFlight.current = null
                }
            },
        [request]
    )
}

function hasRoleFactory(user) {
    return function (role) {
        if (!user) {
            return false
        }

        if (Array.isArray(user.role)) {
            return user.role.indexOf(role) !== -1
        }

        return user.role === role
    }
}

class AuthenticationErrorBoundary extends React.Component {
    state = {
        propagateError: false
    }

    static getDerivedStateFromError(error) {
        return {
            propagateError: error.name !== 'AxiosUnauthorizedError' ? error : null
        }
    }

    componentDidCatch = error => {
        if (error.name === 'AxiosUnauthorizedError') {
            this.props.onUnauthorized()
        }
    }

    render() {
        if (this.state.propagateError) {
            throw this.state.propagateError
        }

        return this.props.children
    }
}

export function AuthenticationContextProvider({ children }) {
    const refreshTokenRequest = useOnceAxiosRequest({
        method: 'post',
        url: '/api/authentication/refresh', 
        data: {}
    })

    const logoutRequest = useAxiosRequest({
        method: 'delete',
        url: '/api/authentication'
    })

    const accessTokenRef = React.useRef()
    const [user, setUser] = React.useState(undefined)
    const [profile, setProfile] = React.useState(null)

    const authenticate = React.useCallback(function ({ accessToken, profile }) {
        accessTokenRef.current = accessToken

        const user = jwtDecode(accessToken)

        setUser(user)
        setProfile(profile || null)

        return {
            user,
            hasRole: hasRoleFactory(user),
            profile
        }
    }, [])

    const clear = React.useCallback(function () {
        accessTokenRef.current = null
        setUser(null)
        setProfile(null)
    }, [])

    const logout = React.useCallback(
        function () {
            logoutRequest()
            clear()
        },
        [logoutRequest]
    )

    const value = React.useMemo(() => {
        return {
            user,
            profile,
            accessTokenRef,
            isReady: user !== undefined,
            isAuthenticated: !!user,
            hasRole: hasRoleFactory(user),
            authenticate,
            clear,
            refreshToken: refreshTokenRequest,
            logout
        }
    }, [user])

    React.useEffect(function checkAuthenticatedState() {
        refreshTokenRequest().then(authenticate).catch(clear)
    }, [])

    return (
        <AuthenticationContext.Provider value={value}>
            <AuthenticationErrorBoundary onUnauthorized={clear}>
                {children}
            </AuthenticationErrorBoundary>
        </AuthenticationContext.Provider>
    )
}

export function useAuthentication() {
    return React.useContext(AuthenticationContext)
}
