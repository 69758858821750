import axios from 'axios'
import React from 'react'

export default function useSubmitSentryAsync() {
    return React.useCallback(function(eventId, values) {
        const dsn = process.env.NEXT_PUBLIC_SENTRY_DSN
        const query = [`dsn=${dsn}`, `eventId=${eventId}`]
        const url = `https://sentry.io/api/embed/error-page/?${query.join('&')}`
        const formData = new FormData()
        formData.append('name', values.name)
        formData.append('email', values.email)
        formData.append('comments', values.comments)
        return axios({
            method: 'post',
            url,
            data: formData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
    }, [])
}