import MuiLink from '@material-ui/core/Link'
import clsx from 'clsx'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React from 'react'

const NextComposed = React.forwardRef(({ as, href, ...other }, ref) => (
    <NextLink href={href} as={as}>
        <a ref={ref} {...other} />
    </NextLink>
))

NextComposed.propTypes = {
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    prefetch: PropTypes.bool
}

const Link = ({
    href,
    activeClassName = 'active',
    className: classNameProps,
    innerRef,
    naked,
    ...other
}) => {
    const router = useRouter()
    const pathname = typeof href === 'string' ? href : href.pathname
    const className = clsx(classNameProps, {
        [activeClassName]: router.pathname === pathname && activeClassName
    })

    if (naked) {
        return (
            <NextComposed
                className={className}
                ref={innerRef}
                href={href}
                {...other}
            />
        )
    }

    return (
        <MuiLink
            component={NextComposed}
            className={className}
            ref={innerRef}
            href={href}
            {...other}
        />
    )
}

Link.propTypes = {
    activeClassName: PropTypes.string,
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    className: PropTypes.string,
    href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    naked: PropTypes.bool,
    onClick: PropTypes.func,
    prefetch: PropTypes.bool
}

export default React.forwardRef((props, ref) => (
    <Link {...props} innerRef={ref} />
))
