import axios from 'axios'
import React from 'react'

import { useAuthentication } from './auth'

const AxiosInstanceContext = React.createContext(axios)

class AxiosUnauthorizedError extends Error {
    constructor(error) {
        super()
        
        this.name = 'AxiosUnauthorizedError'
        this.response = error.response
    }
}

function isUnauthorized(error) {
    return (
        error &&
        error.response &&
        error.response.status === 401
    )
}

export const AxiosInstanceContextProvider = React.memo(
    ({ children, baseUrl }) => {
        const factory = React.useMemo(() => {
            return function () {
                return axios.create({
                    baseURL: baseUrl,
                    withCredentials: true
                })
            }
        }, [baseUrl])

        return (
            <AxiosInstanceContext.Provider value={factory}>
                {children}
            </AxiosInstanceContext.Provider>
        )
    }
)

export function useAxios() {
    const factory = React.useContext(AxiosInstanceContext)
    return React.useMemo(factory, [])
}

const useAxiosRequestOptionsDefaults = {
    returnData: true
}

export function useAxiosRequest(
    options,
    useAxiosRequestOptions = useAxiosRequestOptionsDefaults
) {
    const axios = useAxios()
    return React.useMemo(
        () =>
            async function (runtimeOptions) {
                const mergedOptions = {
                    ...options,
                    ...runtimeOptions
                }

                const response = await axios(mergedOptions)

                if (useAxiosRequestOptions.returnData) {
                    return response.data
                }

                return response
            },
        [options]
    )
}

export function useAuthenticatedAxiosRequest(
    options,
    useAxiosRequestOptions = useAxiosRequestOptionsDefaults
) {
    const {
        accessTokenRef,
        authenticate,
        refreshToken
    } = useAuthentication()
    const axios = useAxios()
    return React.useMemo(
        () =>
            async function (runtimeOptions) {
                const mergedOptions = {
                    ...options,
                    ...runtimeOptions
                }

                const tryRequest = () => {
                    const headers = {
                        ...mergedOptions.headers,
                        Authorization: `Bearer ${accessTokenRef.current}`
                    }

                    return axios({
                        ...mergedOptions,
                        headers
                    })
                }

                let response

                try {
                    if (!accessTokenRef.current) {
                        // this can never happen under normal circumstances
                        // so we do this to not have to throw, which would trigger
                        // the next boundary up
                        await new Promise(resolve => {})
                    }

                    response = await tryRequest()
                } catch (error) {
                    if (!isUnauthorized(error)) {
                        throw error
                    }

                    try {
                        const refreshedToken = await refreshToken(axios)

                        authenticate(refreshedToken)

                        response = await tryRequest()
                    } catch (error) {
                        if(isUnauthorized(error)) {
                            throw new AxiosUnauthorizedError(error)
                        }

                        throw error
                    }
                }

                if (useAxiosRequestOptions.returnData) {
                    return response.data
                }

                return response
            },
        [options]
    )
}
