import { Menu } from '@material-ui/core'
import React from 'react'

function DropDownMenu({ children }, ref) {
    const [anchor, setAnchor] = React.useState(null)

    const open = React.useCallback(e => setAnchor(e.currentTarget), [])
    const close = React.useCallback(() => setAnchor(null), [])

    React.useImperativeHandle(
        ref,
        () => ({
            open,
            close
        }),
        []
    )

    return (
        <Menu
            anchorEl={anchor}
            keepMounted
            open={Boolean(anchor)}
            onClose={close}
        >
            {React.Children.map(children, child =>
                React.cloneElement(child, {
                    ...child.props,
                    onClick: e => {
                        if (child.props.onClick) {
                            child.props.onClick(e)
                        }
                        close()
                    }
                })
            )}
        </Menu>
    )
}

export default React.forwardRef(DropDownMenu)
