import { CssBaseline, ThemeProvider } from '@material-ui/core'
import React from 'react'

export default function BaselineProvider({ children, theme }) {
    React.useEffect(() => {
        const jssStyles = document.querySelector('#jss-server-side')

        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles)
        }
    }, [])

    return (
        <>
            <CssBaseline />
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </>
    )
}
