import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

function getDefaultOptions({ onNetworkError }) {
    function safeOnNetworkError(error) {
        if (!error.response || error.response.status === 401) {
            return
        }

        if (onNetworkError) {
            onNetworkError(error)
        }
    }

    return {
        queries: {
            useErrorBoundary: true,
            retry: (failureCount, error) => {
                if(error.name === 'AxiosUnauthorizedError') {
                    return false
                }

                // retry any queries without response or 500+
                const isMatchingError =
                    !error.response || error.response.status >= 500
                return isMatchingError && failureCount <= 3
            },
            retryDelay: attempt => attempt * 1000,
            onError: safeOnNetworkError
        },
        mutations: {
            retry: (failureCount, error) => {
                if(error.name === 'AxiosUnauthorizedError') {
                    return false
                }
                
                // for mutations only retry one without a response
                const isMatchingError = !error.response
                return isMatchingError && failureCount <= 3
            },
            retryDelay: attempt => attempt * 1000,
            onError: safeOnNetworkError
        }
    }
}

const queryClient = new QueryClient()

export default function ConfiguredQueryClientProvider({ children, ...props }) {
    React.useEffect(
        function updateOptions() {
            queryClient.setDefaultOptions(getDefaultOptions(props))
        },
        [props]
    )

    return (
        <QueryClientProvider client={queryClient}>
            {children}
            <ReactQueryDevtools />
        </QueryClientProvider>
    )
}
