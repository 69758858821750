import { DataGrid } from '@material-ui/data-grid'
import { noop } from 'lodash'
import React from 'react'

const defaultRowsPerPageOptions = [25, 50, 100]

function defaultGetRowId(row) {
    return row.id
}

export default function PagedResultDataGrid({
    columns,
    items,
    totalCount,
    isLoading,
    getRowId = defaultGetRowId,
    skip = 0,
    limit = 25,
    orderByProperty,
    orderByDirection,
    rowsPerPageOptions = defaultRowsPerPageOptions,
    checkboxSelection = false,
    disableSelectionOnClick = false,
    onChange = noop,
    onSelect = noop,
    className,
    propForRowColor,
    ...props
}) {
    const onPageChange = React.useCallback(
        ({ page }) => {
            onChange({
                skip: limit * page
            })
        },
        [limit, onChange]
    )

    const onPageSizeChange = React.useCallback(
        ({ pageSize }) => {
            onChange({
                skip: 0,
                limit: pageSize
            })
        },
        [limit, onChange]
    )

    const onSortChange = React.useCallback(
        ({ sortModel }) => {
            onChange({
                orderByProperty:
                    sortModel.length > 0 ? sortModel[0].field : null,
                orderByDirection:
                    sortModel.length > 0 ? sortModel[0].sort : 'asc'
            })
        },
        [onChange]
    )

    const onRowSelect = React.useCallback(
        ({ data, isSelected }) => {
            onSelect({
                [getRowId(data)]: isSelected
            })
        },
        [onSelect]
    )

    return (
        <DataGrid
            columns={columns}
            rows={items}
            rowCount={totalCount}
            getRowId={getRowId}
            loading={isLoading}
            // pagination
            pagination
            paginationMode="server"
            pageSize={limit}
            page={skip / limit}
            rowsPerPageOptions={rowsPerPageOptions}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            // sorting
            sortModel={[
                {
                    field: orderByProperty,
                    sort: orderByDirection
                }
            ]}
            sortingOrder={['desc', 'asc']}
            sortingMode="server"
            onSortModelChange={onSortChange}
            // selection
            checkboxSelection={checkboxSelection}
            onRowSelected={onRowSelect}
            // options
            autoHeight
            disableColumnMenu
            disableSelectionOnClick={disableSelectionOnClick}
            onError={error => console.error('Error rendering table', error)}
            className={className}
            getRowClassName={params =>
                `${params.getValue(params.id, propForRowColor)}`
            }
            {...props}
        />
    )
}
