import { BackdropProgress } from '@zmvp/material-ui'
import { useRouter } from 'next/router'
import React from 'react'
import { useAuthentication } from './context'

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export function WhenAuthenticated({ children }) {
    const { isAuthenticated } = useAuthentication()

    return isAuthenticated ? children : null
}

export function WhenUnauthenticated({ children }) {
    const { isAuthenticated } = useAuthentication()

    return isAuthenticated ? null : children
}

export function WhenRole({ children, role }) {
    const { hasRole } = useAuthentication()

    return hasRole(role) ? children : null
}

export function RequireAuthenticated({ children, redirectTo = '/login' }) {
    const router = useRouter()
    const { isReady, isAuthenticated } = useAuthentication()

    React.useEffect(
        function redirectIfNotAuthenticated() {
            if (isReady && !isAuthenticated) {
                router.push(redirectTo)
            }
        },
        [isReady, isAuthenticated]
    )

    return isAuthenticated ? children : <BackdropProgress />
}

export function withRequireAuthenticated(WrappedComponent, requireProps) {
    const hoc = function (props) {
        return (
            <RequireAuthenticated {...requireProps}>
                <WrappedComponent {...props} />
            </RequireAuthenticated>
        )
    }
    hoc.displayName = `WithRequireAuthenticated(${getDisplayName(
        WrappedComponent
    )})`
    return hoc
}

export function RequireRole({ children, role, redirectTo = '/login' }) {
    const router = useRouter()
    const { isReady, hasRole } = useAuthentication()

    React.useEffect(
        function redirectIfNotAuthenticated() {
            if (isReady && !hasRole(role)) {
                router.push(redirectTo)
            }
        },
        [isReady, hasRole(role)]
    )

    return !isReady || !hasRole(role) ? <BackdropProgress /> : children
}

export function withRequireRole(WrappedComponent, requireProps) {
    const hoc = function (props) {
        return (
            <RequireRole {...requireProps}>
                <WrappedComponent {...props} />
            </RequireRole>
        )
    }
    hoc.displayName = `WithRequireRole(${getDisplayName(
        WrappedComponent
    )})`
    return hoc
}

export function RequireAdmin({ children, redirectTo = '/login' }) {
    return (
        <RequireRole role="admin" redirectTo={redirectTo}>
            {children}
        </RequireRole>
    )
}

export function withRequireAdmin(WrappedComponent, requireProps) {
    const hoc = function (props) {
        return (
            <RequireAdmin {...requireProps}>
                <WrappedComponent {...props} />
            </RequireAdmin>
        )
    }
    hoc.displayName = `WithRequireAdmin(${getDisplayName(
        WrappedComponent
    )})`
    return hoc
}
