import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles
} from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(theme => ({
    topBar: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    contentContainer: {
        outline: 'none'
    }
}))

const ScrollPaperDialog = React.forwardRef(
    (
        {
            children,
            title,
            actions,
            dividers,
            disableTypography,
            isFullScreen,
            ...props
        },
        ref
    ) => {
        const [isOpen, setIsOpen] = React.useState(false)
        const textRef = React.useRef()

        ref.current = {
            open: () => setIsOpen(true),
            close: () => setIsOpen(false),
            toggle: state => setIsOpen(state)
        }

        const onClose = React.useCallback(() => {
            setIsOpen(false)
        }, [])

        React.useEffect(() => {
            if (isOpen) {
                if (textRef.current) {
                    textRef.current.focus()
                }
            }
        }, [isOpen])

        const classes = useStyles()

        return (
            <Dialog
                open={isOpen}
                onClose={onClose}
                scroll="paper"
                fullScreen={isFullScreen}
                {...props}
            >
                {title && (
                    <DialogTitle
                        disableTypography={disableTypography}
                        className={isFullScreen ? classes.topBar : null}
                    >
                        {title}
                    </DialogTitle>
                )}
                <DialogContent dividers={dividers}>
                    <div
                        ref={textRef}
                        tabIndex={-1}
                        className={classes.contentContainer}
                    >
                        {children}
                    </div>
                </DialogContent>
                <DialogActions>
                    {actions || (
                        <Button onClick={onClose} color="secondary">
                            Close
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        )
    }
)

export default ScrollPaperDialog
