import { createTheme } from '@material-ui/core'

const theme = createTheme({
    palette: {
        secondary: {
            main: '#1B224A'
        },
        info: {
            main: '#C4C4C4'
        },
    },
    overrides: {
        MuiFormHelperText: {
            contained: {
                marginLeft: 0
            }
        }
    }
})

export default theme
