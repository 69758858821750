import debounce from 'lodash/debounce'
import React from 'react'

export default function useDebouncedState(
    value,
    { delay = 750, maxWait = 3000, onUpdate } = {}
) {
    const [debouncedValue, setValue] = React.useState(value)

    const debouncedSetValue = React.useCallback(
        debounce(
            value => {
                setValue(value)

                if (onUpdate) {
                    onUpdate(value)
                }
            },
            delay,
            { maxWait }
        ),
        []
    )

    // when value changes, debounce-set

    React.useEffect(() => {
        debouncedSetValue(value)
    }, [value])

    return [
        debouncedValue,
        debouncedSetValue,
        setValue // allow caller to immediately set value
    ]
}
