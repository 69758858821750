import { ServerStyleSheets } from '@material-ui/core'
import NextDocument, { Html, Head, Main, NextScript } from 'next/document'
import React from 'react'

const Document = class extends NextDocument {
    render() {
        return (
            <Html lang="en">
                <Head />
                <body>
                    <Main />
                    <NextScript />
                </body>
            </Html>
        )
    }
}

Document.getInitialProps = async ctx => {
    const sheets = new ServerStyleSheets()
    const originalRenderPage = ctx.renderPage

    ctx.renderPage = function () {
        return originalRenderPage({
            enhanceApp: App => props => sheets.collect(<App {...props} />)
        })
    }

    const initialProps = await NextDocument.getInitialProps(ctx)

    return {
        ...initialProps,
        styles: [
            ...React.Children.toArray(initialProps.styles),
            sheets.getStyleElement()
        ]
    }
}

export default Document
