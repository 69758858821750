import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { isString } from 'lodash'
import React from 'react'

const ActionSnackbarContext = React.createContext(null)

const defaultOptions = {
    anchor: {
        vertical: 'bottom',
        horizontal: 'right'
    },
    type: 'success',
    autoHideDuration: 6000,
    message: null
}

export function ActionSnackbarProvider({ children }) {
    const [isOpen, setIsOpen] = React.useState(false)
    const [options, setOptions] = React.useState(defaultOptions)

    const open = React.useCallback(options => {
        if (isString(options)) {
            setOptions({
                ...defaultOptions,
                message: options
            })
        } else {
            setOptions({
                ...defaultOptions,
                ...options
            })
        }

        setIsOpen(true)
    }, [])

    const close = React.useCallback(() => {
        setIsOpen(false)
    }, [])

    const value = React.useMemo(
        () => ({
            open
        }),
        []
    )

    return (
        <ActionSnackbarContext.Provider value={value}>
            {children}
            <Snackbar
                anchorOrigin={options.anchor}
                open={isOpen}
                autoHideDuration={options.autoHideDuration}
                onClose={close}
            >
                <Alert onClose={close} severity={options.type}>
                    {options.message}
                </Alert>
            </Snackbar>
        </ActionSnackbarContext.Provider>
    )
}

export function useActionSnackbar() {
    const context = React.useContext(ActionSnackbarContext)

    if(!context) {
        throw new Error(`ActionSnackbarContext not found.`)
    }

    return context.open
}
